import { post } from '@/utils/request';

const createStreamTypeReq = ({ name, type, id }) => post({
  url: '/store/system.Stream/createStreamType',
  data: {
    name,
    type,
    id,
  },
});

const streamTypeListReq = (type) => post({
  url: '/store/system.Stream/streamTypeList',
  data: {
    type,
  },
});

const addReq = ({ type, stream_type_id, pay_channel, money, remark }) => post({
  url: '/store/system.Stream/add',
  data: {
    type,
    stream_type_id,
    pay_channel,
    money,
    remark,
  },
});

const listReq = (cond) => post({
  url: '/store/system.Stream/list',
  data: {
    ...cond,
  },
});

const streamTypeDelReq = (id	) => post({
  url: '/store/system.Stream/streamTypeDel',
  data: {
    id,
  },
});

export {
  createStreamTypeReq,
  streamTypeListReq,
  addReq,
  listReq,
  streamTypeDelReq,
};
