<template>
  <div>
    <el-dialog
      title="添加其他收支"
      :visible.sync="dialogVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="handleClose">
      <el-form :model="form" label-width="120px">
        <el-form-item label="选择收支类型">
          <el-select v-model="form.type" placeholder="请选择收支类型" @change="handleChange">
            <el-option label="收入" :value="10"></el-option>
            <el-option label="支出" :value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="款项类型">
          <el-select v-model="form.stream_type_id" placeholder="请选择收支类型">
            <template v-for="item in streamTypeList">
              <el-option :label="item.name" :value="item.id" :key="item.id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="收款方式">
          <el-radio-group v-model="form.pay_channel">
            <el-radio :label="10">现金</el-radio>
            <el-radio :label="20">支付宝</el-radio>
            <el-radio :label="30">微信</el-radio>
            <el-radio :label="40">银联</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="form.money" placeholder="请输入金额" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { streamTypeListReq, addReq } from '@/api/order/account/list';

export default {
  data() {
    return {
      loading: false,
      form: {},
      streamTypeList: [], // 款项类型列表
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleTrue() {
      this.loading = true;
      addReq({ ...this.form })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.$message.success('添加成功');
            this.handleClose();
            this.$emit('onGetList');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleChange(val) {
      const form = { ...this.form };
      form.stream_type_id = '';
      this.form = form;
      streamTypeListReq(val)
        .then((res) => {
          if (res.code === 1) {
            this.streamTypeList = res.data;
          }
        });
    },
    handleClose() {
      setTimeout(() => {
        this.form = {};
      }, 500);
      this.$emit('onHideNewAccount');
    },
  },
};
</script>
