<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="收支详情">
      </el-page-header>
    </div>
    <div class="list">
      <div class="item">
        <div class="left">收支类型</div>
        <div>{{currRow.type_text}}</div>
      </div>
      <div class="item">
        <div class="left">添加人</div>
        <div>{{currRow.create_user_name}}</div>
      </div>
      <div class="item">
        <div class="left">添加时间</div>
        <div>{{currRow.create_time}}</div>
      </div>
      <div class="item">
        <div class="left">款项类型</div>
        <div>{{currRow.stream_type_text}}</div>
      </div>
      <div class="item">
        <div class="left">收款方式</div>
        <div>{{currRow.pay_channel}}</div>
      </div>
      <div class="item">
        <div class="left">金额</div>
        <div>{{currRow.money}}</div>
      </div>
      <div class="item">
        <div class="left">备注</div>
        <div>{{currRow.remark}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    currRow: Object,
  },
  methods: {
    goBack() {
      this.$emit('onHideDetail');
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 50px 10px 50px;
  background: white;
  .item {
    width: 33%;
    display: flex;
    margin-bottom: 20px;
    .red {
      color: #E7541E;
      font-size: 12px;
      margin-left: 10px;
      margin-top: 3px;
    }
    .left::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791FF;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    div:nth-child(1) {
      width: 100px;
      color: #909399;
      position: relative;
    }
    div:nth-child(2) {
      color: #565656;
    }
  }
}
</style>
