<template>
  <div>
    <template v-if="!detailVisible">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="dialogVisible = true"
            >新增其他收支</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addVisible = true"
            >新增款项</el-button
          >
          <el-button type="primary" plain @click="listVisible = true"
            >款项列表</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-upload2"
            :loading="loading3"
            @click="handleExport"
            >导出</el-button
          >
        </div>
        <div class="form">
          <el-form :model="formInline" :inline="true">
            <el-form-item label="交易时间">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="formInline.create_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="total">
        <div class="item">
          <div class="money">{{ tableData.revenue }}</div>
          <div>总盈收(元)</div>
        </div>
        <div class="item">
          <div class="block">
            <div class="money">{{ tableData.income }}</div>
            <div>收入(元)</div>
          </div>
          <div class="line"></div>
          <div class="block">
            <div class="money">{{ tableData.outlay }}</div>
            <div>支出(元)</div>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData.list"
          stripe
          v-loading="loading"
          height="580"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
              <template slot-scope="scope">
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            field_alias="operation"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <newAccount
        :dialogVisible="dialogVisible"
        @onHideNewAccount="handleHideNewAccount"
        @onGetList="getList"
      />
      <el-dialog
        :title="form.id ? '编辑款项' : '添加款项'"
        :visible.sync="addVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose"
      >
        <el-form :model="form" label-width="120px">
          <el-form-item label="款项名称">
            <el-input
              v-model="form.name"
              placeholder="请输入款项名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款类型">
            <el-radio-group v-model="form.type">
              <el-radio :label="10">收入</el-radio>
              <el-radio :label="20">支出</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleTrue" :loading="loading2"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="款项列表"
        :visible.sync="listVisible"
        width="50%"
        :before-close="handleClose2"
      >
        <div class="table">
          <el-table :data="streamTypeList" stripe v-loading="loading">
            <template v-for="(item, index) in rowList2">
              <el-table-column
                align="center"
                :key="index"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
              >
                <template slot-scope="scope">
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align="center"
              fixed="right"
              field_alias="operation"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.row)"
                  :disabled="scope.row.is_edit ? false : true"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleDele(scope.row.id)"
                  :disabled="scope.row.is_edit ? false : true"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose2">取 消</el-button>
          <el-button type="primary" @click="handleClose2">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <Detail
      v-if="detailVisible"
      @onHideDetail="handleHideDetail"
      :currRow="currRow"
    />
  </div>
</template>

<script>
import moment from "moment";
import {
  createStreamTypeReq,
  listReq,
  streamTypeListReq,
  streamTypeDelReq,
} from "@/api/order/account/list";
import NewAccount from "./newAccount.vue";
import Detail from "./Detail.vue";

export default {
  data() {
    return {
      loading3: false,
      currRow: {},
      streamTypeList: [],
      page: 1,
      limit: this.$store.state.pageSizes[0],
      loading2: false,
      detailVisible: false,
      rowList2: [
        {
          field_text: "款项名称",
          field_alias: "name",
        },
        {
          field_text: "收支类型",
          field_alias: "type_text",
        },
      ],
      data: [{}],
      listVisible: false,
      addVisible: false, // 新增款项
      form: {},
      dialogVisible: false,
      loading: false,
      formInline: {},
      rowList: [
        {
          field_text: "交易时间",
          field_alias: "create_time",
        },
        {
          field_text: "全部收支类型",
          field_alias: "type_text",
          filed_status: [
            { value: "收入", text: "收入" },
            { value: "支出", text: "支出" },
          ],
        },
        {
          field_text: "款项类型",
          field_alias: "stream_type_text",
        },
        {
          field_text: "全部流水来源",
          field_alias: "stream_group_text",
          filed_status: [
            { value: "订单", text: "订单" },
            { value: "其他收支", text: "其他收支" },
          ],
        },
        // {
        //   field_text: '收款方式',
        //   field_alias: 'create_time',
        // },
        {
          field_text: "款项总金额",
          field_alias: "money",
        },
        {
          field_text: "备注",
          field_alias: "remark",
        },
        {
          field_text: "来源单号",
          field_alias: "from_number",
          t_width: 250,
        },
      ],
      tableData: {
        list: [{}],
        total: 1,
      },
    };
  },
  components: {
    NewAccount,
    Detail,
  },
  created() {
    this.getList();
    this.getStreamTypeList();
  },
  methods: {
    handleDalclick(row) {
      this.toDetail(row);
    },
    resetForm() {
      this.formInline = {};
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      listReq({ page: this.page, limit: this.limit, export: 2 })
        .then((res) => {
          this.loading3 = false;
          res = "\ufeff" + res;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute(
            "download",
            `收支流水导出.csv`
          );
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    handleEdit(row) {
      this.form = { ...row };
      this.addVisible = true;
    },
    handleDele(id) {
      streamTypeDelReq(id).then((res) => {
        if (res.code === 1) {
          this.getStreamTypeList();
        }
      });
    },
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    getStreamTypeList() {
      streamTypeListReq().then((res) => {
        if (res.code === 1) {
          this.streamTypeList = res.data;
        }
      });
    },
    getList() {
      this.loading = true;
      listReq({
        create_time: this.formInline.create_time,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.create_time = moment(item.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 确定新增款项
    handleTrue() {
      this.loading2 = true;
      createStreamTypeReq({ ...this.form })
        .then((res) => {
          this.loading2 = false;
          if (res.code === 1) {
            if (this.form.id) {
              this.$message.success("编辑成功");
            } else {
              this.$message.success("添加成功");
            }
            this.handleClose();
            this.getStreamTypeList();
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    toDetail(row) {
      // if (row.stream_group_text === '其他收支') {
      //   this.currRow = row;
      //   this.detailVisible = true;
      // }
      this.currRow = row;
      this.detailVisible = true;
    },
    handleHideDetail() {
      this.currRow = {};
      this.detailVisible = false;
    },
    handleClose2() {
      this.listVisible = false;
    },
    handleClose() {
      setTimeout(() => {
        this.form = {};
      }, 500);
      this.addVisible = false;
    },
    // 隐藏新增收支类型的弹窗
    handleHideNewAccount() {
      this.dialogVisible = false;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
  },
};
</script>

<style scoped lang="less">
.left {
  padding-right: 30px;
  border-right: 1px solid #ddd;
}
.form {
  padding-left: 30px;
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
.total {
  display: flex;
  background: white;
  margin-top: 10px;
  padding: 20px;
  .item {
    height: 90px;
    padding: 0 60px;
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    text-align: center;
    font-size: 14px;
    .money {
      font-size: 28px;
      margin-top: 15px;
    }
  }
  .item:nth-child(1) {
    background: linear-gradient(#fd7e5d 0%, #ff628a 100%);
    margin-right: 20px;
  }
  .item:nth-child(2) {
    display: flex;
    background: linear-gradient(#5aabfe 0%, #597bf7 100%);
    .block {
      padding: 0 30px;
    }
    .line {
      width: 1px;
      height: 50px;
      background: white;
      margin-top: 20px;
    }
  }
}
</style>
